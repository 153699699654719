<template>
    <form-modal
        ref="formModal"
        :in-editing="true"
        :title="i18nCommentText"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row class="modal-body message">
                    <b-col md="12" class="mt-2">
                        <b-form-group
                            :label="i18nComment"
                            class="requiredlabel-pdv"
                            label-for="comment"
                            :invalid-feedback="i18nInvalidReasonLength"
                        >
                            <b-form-textarea
                                id="comment"
                                v-model.trim="$v.modal.comment.$model"
                                class="input-pdv-blue"
                                rows="6"
                                max-rows="6"
                                autofocus
                                autocomplete="off"
                                :disabled="submitLoading"
                                :state="validateField('comment', 'modal')"
                                @input="clearResponseError('comment', 'modal')"
                                @blur="$v.modal.comment.$touch"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <!-- BOTÃO Comentar -->
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :title="i18nComment"
                @onClick="handleOk"
            />
            <!-- BOTÃO Cancelar -->
            <cancel-label-button
                :disabled="submitLoading"
                @onClick="handleCancel"
            />
        </template>
    </form-modal>
</template>

<script>
    import FilesService from '@/src/services/FilesService.js'

    import { requiredIf, minLength } from 'vuelidate/lib/validators'
    import swalFeedback from '@src/mixins/swalFeedback.vue'
    import validationMixin from '@src/mixins/validation.vue'
    import fieldsValidation from '@src/mixins/fieldsValidation.vue'
    import FormModal from '@/src/components/FormModal.vue'
    import SaveButton from '@/src/components/SaveButton'
    import CancelLabelButton from '@/src/components/CancelLabelButton'

    const i18nCommon = 'COMMON'

    export default {
        components: {
            FormModal,
            SaveButton,
            CancelLabelButton,
        },
        mixins: [
            validationMixin,
            swalFeedback,
            fieldsValidation,
        ],
        data() {
            return {
                i18nCommon,
                submitLoading: false,
                modal: {
                    id: 0,
                    comment: '',
                }
            }
        },
        validations: {
            modal: {
                comment: {
                    required: requiredIf(function () {
                        return true;
                    }),
                    minLength: minLength(15),
                },
            },
        },
        computed: {
            i18nApprovePhoto() {
                return `${this.getI18n(i18nCommon, 'approve')} ${this.getI18n(i18nCommon, 'photo')}`
            },
            i18nApprovalConfirmationText() {
                return this.getI18n('RESPONSES', 'approval_confirmation_text')
            },
            i18nRejectPhoto() {
                return `${this.getI18n(i18nCommon, 'reject')} ${this.getI18n(i18nCommon, 'photo')}`
            },
            i18nRejectConfirmationText() {
                return this.getI18n('RESPONSES', 'reject_confirmation_text')
            },
            i18nInvalidReasonLength() {
                return this.getI18n('ERROR_CODES', 'invalid_reason_length')
            },
            i18nReason() {
                return this.getI18n(i18nCommon, 'reason')
            },
            i18nApprove() {
                return this.getI18n(i18nCommon, 'approve')
            },
            i18nReject() {
                return this.getI18n(i18nCommon, 'reject')
            },
            i18nCommentText() {
                return this.getI18n('RESPONSES', 'comment_text')
            },
            i18nComment() {
                return this.getI18n('DASHBOARDS.TYPES.comment_photo')
            },
        },
        methods: {
            showModal(id, comment) {
                console.log(comment);
                this.modal = {
                    id: id,
                    comment: comment,
                }
                this.submitLoading = false
                this.$refs.formModal.show()
                this.resetValidation()
            },
            clearAllResponseError() {
                this.modalResponseErrors = {}
            },
            resetValidation() {
                this.$nextTick(() => this.$v.$reset())
                this.clearAllResponseError()
            },
            handleCancel() {
                this.resetValidation()
                this.$refs.formModal.hide()
            },
            handleOk() {
                this.$v.modal.$touch()
                if (!this.$v.modal.$invalid && !this.submitLoading) {
                    this.submitLoading = true
                    this.handleSubmit()
                }
            },
            handleComments() {
                this.$v.modal.$touch()
            },
            async handleSubmit() {
                FilesService.update(this.modal.id, {
                    comment: this.modal.comment,
                })
                    .then((response) => {
                        this.data = response.data.data
                        this.totalItems = response.data.meta.total
                        this.perPage = response.data.meta.per_page
                        this.loading = false
                    })
                    .catch((err) => {
                        this.loading = false
                        return err
                    })
                    this.$emit('refresh', this.modal.id, this.modal.comment)
                this.$refs.formModal.hide()
            }
        },
    }
</script>
