import BaseService from '@src/services/BaseService'
import * as storeFile from "@state/store";
import axios from "axios";

class ImportJobService extends BaseService {
    constructor() {
        super('export-jobs')
    }

    getUrl() {
        return `${process.env.VUE_APP_API_ENDPOINT}/${this.filterTenant ? `tenant/${storeFile.default.getters['auth/tenantId']}/` : ''}${this.urlRoute}`
    }

    getOldUrl() {
        return `${process.env.VUE_APP_OLD_API_ENDPOINT}/${this.filterTenant ? `tenant/${storeFile.default.getters['auth/tenantId']}/` : ''}${this.urlRoute}`
    }

    create(params) {
        let url = params.export_type === 'questionnaire_answer' ? this.getOldUrl() : this.getUrl();
        return axios.post(`${url}/create`, params)
    }
}

export default new ImportJobService()
